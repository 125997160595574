import React, {Component} from 'react'
import {Link, useLocation} from 'react-router-dom'

class Menu extends Component {

    render(){

        let menu_items = [
            { href: '/', label: 'Kiezen'},
            { href: '/overzicht/', label: 'Overzicht'},
        ]

        return (
            <React.Fragment>
                <div className="menu">
                    <ul>
                        {menu_items.map( (item) => (
                            <li key={item.href} className={'menu-item' + (item.href === window.location.pathname ? ' current-menu-item' : '')}>
                                <Link to={item.href}>{item.label}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}
export default Menu
