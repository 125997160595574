import React, { Component } from 'react'
import {connect} from "react-redux";
import {fetchEntries} from "../../actions/croissant";
import Loading from "../Includes/Loading";
import {formatDate} from "../Includes/FormatDate";
import svg_print from "../../static/images/printer.svg";

class Overview extends Component {

    state = {
        filter: {
            date: ''
        }
    }

    componentDidMount() {
        this.props.fetchEntries();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if( prevProps.list.length !== this.props.list.length && this.props.list.length > 0 ){

            let list = this.props.list.sort(function (a, b) {
                return parseInt(b.date) - parseInt(a.date)
            })

            this.setState(prevstate => {
                const newState = {...prevstate};
                newState['filter']['date'] = list[0].date;
                return newState;
            });
        }
    }

    handle_change = e => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState(prevstate => {
            const newState = {...prevstate};
            newState['filter'][name] = value;
            return newState;
        });
    };

    onSubmit = (event) => {
        event.preventDefault();
    }

    getFilterDates = (list) => {

        let dates = [];
        list.map((el) => {
            dates.push({ label: el.date, value: parseInt(el.date) });
            return false
        })

        dates.sort(function (a, b) {
            return b.value - a.value
        })

        return dates;
    }

    filterList = (list) => {

        let filter_date = this.state.filter.date

        return list.filter(function (day) {
            return day.date === filter_date
        })
    }

    countCroissants = (days) => {

        let list = {}
        let count = 0;
        let day = this.filterList(days);

        if( day.length > 0 ){
            day[0].list.map((el) => {
                list[el.croissant] = list[el.croissant] !== undefined ? (list[el.croissant] + 1) : 1
                count++;
                return false;
            })
        }

        return {list: list, count: count}
    }

    printPage = () => {
        window.print();
    }

    render(){

        if( this.props.list.length === 0 || this.state.filter.date === '') {
            return <Loading />
        }

        let filter_dates = this.getFilterDates(this.props.list)
        let chosen_croissants = this.countCroissants(this.props.list)


        let filter = (
            <form id="filter" onSubmit={this.onSubmit}>
                <div className="form-field h-align">
                    <h3>Inzendingen van:</h3>
                    <select name="date" defaultValue={this.state.filter.date} onChange={this.handle_change}>
                        {filter_dates.map( (date) => (
                            <option key={date.value} value={date.value}>{formatDate(date.label)}</option>
                        ))}
                    </select>
                </div>
            </form>
        )

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="page-content">

                            <div className="title-container">
                                <h1>Overzicht van inzendingen</h1>
                                <div className="link" onClick={this.printPage}>
                                    <img src={svg_print} className="icon" /> Printen</div>
                            </div>

                            {filter}

                            {Object.keys(chosen_croissants).length > 0 ? (<React.Fragment>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Croissant</th>
                                        <th>Aantal</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {Object.keys(chosen_croissants.list).sort(function(a, b){ return a - b }).map((key) => (
                                        <tr key={key}>
                                            <td>{key}</td>
                                            <td>{chosen_croissants.list[key]}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td>Totaal</td>
                                        <td>{chosen_croissants.count}</td>
                                    </tr>
                                    </tfoot>
                                </table>
                                <p><em><strong>Let op!</strong> Tel altijd de aantal medewerkers en vergelijk deze met het totaal. Zodat je zeker weet dat er geen mensen zijn die geen croissant hebben ingevuld.</em></p>
                            </React.Fragment>) : 'Geen croissants'}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    list: state.croissant.list,
    errors: state.croissant.errors
})

const mapDispatchToProps = (dispatch) => ({
    fetchEntries: () => {
        dispatch(fetchEntries())
    },
})


export default connect(mapStateToProps,mapDispatchToProps)(Overview)
