import React, { Component } from 'react'
import {Route, Routes} from 'react-router-dom'
import Overview from './Overview'
import Reminder from './Reminder'
import Form from './Form'
import Notifications from "./Notifications";

class Main extends Component {

    render(){

        return (
            <main>
                <Notifications />
                <Routes>
                    <Route path='/herinner-mij/' element={<Reminder/>} />
                    <Route path='/overzicht/' element={<Overview/>} />
                    <Route exact path='/' element={<Form/>} />
                </Routes>
            </main>
        );
    }
}

export default Main
