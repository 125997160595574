import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {connect} from "react-redux";
import {postCroissant} from "../../actions/croissant";
import img_croissant_normaal from '../../static/images/croissants/normaal.jpeg'
import img_croissant_kaas from '../../static/images/croissants/kaas.jpeg'
import img_croissant_hamkaas from '../../static/images/croissants/ham-kaas.jpeg'
import img_croissant_chocolade from '../../static/images/croissants/chocolade.jpeg'
import img_croissant_meerzaden from '../../static/images/croissants/meerzaden.jpeg'
import img_italiaanse_bol_wit from '../../static/images/croissants/italiaanse-bol-wit.jpeg'
import img_meergranen_triangel from '../../static/images/croissants/meergranen-triangel.jpeg'
import img_pistolet_wit from '../../static/images/croissants/pistolet-wit.jpeg'
import img_pistolet_bruin from '../../static/images/croissants/pistolet-bruin.jpeg'
import img_pistolet_volkoren from '../../static/images/croissants/pistolet-volkoren.jpeg'
import img_frikandelbroodje from '../../static/images/croissants/frikandelbroodje.jpg'
import img_saucijzenbroodje from '../../static/images/croissants/saucijzenbroodje.jpg'
import '../../static/scss/croissant-form.scss';
import {FloatingLabels} from "../Includes/FloatingLabels";

class Form extends Component {

    state = {
        fields: {
            name: '',
            croissant: '',
        },
        errors: {},
        cheatcode: '',
    }

    componentDidMount() {

        if( localStorage.getItem("croissantForm") !== null ){

            let localFields = JSON.parse(localStorage.getItem("croissantForm"));
            this.setState({ fields: localFields })
        }

        FloatingLabels();

        this.cheatCode();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        FloatingLabels();
    }

    cheatCode = (e) => {

        let self = this;

        window.addEventListener('keydown', function (e) {
            self.setState({ cheatcode: self.state.cheatcode + e.keyCode })
        })
    }

    handle_change = e => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState(prevstate => {
            const newState = {...prevstate};
            newState['fields'][name] = value;
            return newState;
        });
    };

    allowSubmit = () => {

        let allowed = true;
        let errors = {}

        let keys = Object.keys(this.state.fields)
        for(let i = 0; i < keys.length; i++){
            let field_valid = this.state.fields[keys[i]] !== undefined && this.state.fields[keys[i]].trim() !== '' ? true : false
            if( !field_valid ){
                allowed = false;
                errors[keys[i]] = 'Vul een waarde in'
            }
        }

        if( Object.keys(errors).length > 0 ){
            this.setState({ errors: errors })
        }

        return allowed;
    }

    onSubmit = (event) => {
        event.preventDefault();

        if( !this.allowSubmit() )
            return false;


        localStorage.setItem('croissantForm', JSON.stringify(this.state.fields));

        this.props.postCroissant(this.state.fields)

    }

    render(){

        const queryParams = new URLSearchParams(window.location.search)
        const debug = queryParams.get("debug")

        let form;
        let d = new Date();
        let croissants = [
            {label: 'Croissant', value: 'normaal', img: img_croissant_normaal},
            {label: 'Kaas croissant', value: 'kaas', img: img_croissant_kaas},
            {label: 'Ham-kaas croissant', value: 'ham-kaas', img: img_croissant_hamkaas},
            {label: 'Chocolade croissant', value: 'chocolade', img: img_croissant_chocolade},
            {label: 'Meerzaden croissant', value: 'meerzaden', img: img_croissant_meerzaden},
            {label: 'Italiaanse bol wit', value: 'italiaanse-bol-wit', img: img_italiaanse_bol_wit},
            {label: 'Meergranen triangel', value: 'meergranen-triangel', img: img_meergranen_triangel},
            {label: 'Pistolet wit', value: 'pistolet-wit', img: img_pistolet_wit},
            {label: 'Pistolet bruin', value: 'pistolet-bruin', img: img_pistolet_bruin},
            {label: 'Pistolet volkoren', value: 'pistolet-volkoren', img: img_pistolet_volkoren},
        ]

        if( this.state.cheatcode.includes('3838404037393739666513') ){
            croissants.push({label: 'Frikandelbroodje', value: 'frikandelbroodje', img: img_frikandelbroodje})
            croissants.push({label: 'Saucijzenbroodje', value: 'saucijzenbroodje', img: img_saucijzenbroodje})
        }

        let selectedSlug = this.state.fields.croissant;

        let selected = croissants.filter(function(v,i) {
            return v.value === selectedSlug;
        })[0];

        let submitLabel;
        if( typeof selected !== 'undefined') {
            submitLabel = 'Ik wil graag een ' + selected.label
        } else {
            submitLabel = 'Kies een product'
        }
        console.log()

        if( d.getDay() === 4 || (debug !== null && debug === 'true') ) {

            form = (<form onSubmit={this.onSubmit} className="floating-labels">

                <div className="form-field">
                    <label className="field-label" htmlFor="id_name">Je naam</label>
                    <input type="text" name="name" id="id_name" value={this.state.fields.name} onChange={this.handle_change} />
                    {Object.keys(this.state.errors).length > 0 && this.state.errors.name !== undefined ? <div className="field-error">{this.state.errors.name}</div> : '' }
                </div>

                <div className="form-field no-floating-label">
                    <label className="field-label" htmlFor="id_croissant"><strong>Kies een product</strong></label>

                    <ul className="radio field-croissant">
                        {croissants.map( (option) => (
                            <li key={option.value} className={"input-radio"}>
                                <input
                                    type="radio"
                                    name="croissant"
                                    id={'croissant-' + option.value}
                                    value={option.value}
                                    checked={this.state.fields.croissant === option.value}
                                    onChange={this.handle_change}
                                />
                                <label htmlFor={'croissant-' + option.value}>
                                    <div className="image"><img src={option.img} alt={option.label} /></div>
                                    <span>{option.label}</span>
                                </label>
                            </li>
                        ))}
                    </ul>
                    {Object.keys(this.state.errors).length > 0 && this.state.errors.croissant !== undefined ? <div className="field-error">{this.state.errors.croissant}</div> : '' }
                </div>

                <div className="form-submit">
                    <input type="submit" name="submit" value={submitLabel.toLowerCase()} />
                </div>

            </form>)
        } else {

            form = <p><strong>Helaas! Het is vandaag geen croissantjes-dag.</strong></p>
        }

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="page-content">
                            <h1>Every thursday because I loaf you</h1>

                            {form}

                            {/*<div className="page-call-to-action">*/}
                            {/*    <h3>Wil je elke week herinnerd worden?</h3>*/}
                            {/*    <p>Wij kunnen jou elke donderdagochtend een mail sturen ter herinnering.<br/><Link className="button" to={"/herinner-mij/"}>Ja, herinner mij!</Link></p>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    postCroissant: (body) => {
        dispatch(postCroissant(body))
    },
})


export default connect(null,mapDispatchToProps)(Form)
