export const FloatingLabels = (props) => {

    // Floating labels
    var floating_labels = document.querySelectorAll(".floating-labels .form-field input, .floating-labels .form-field textarea, .floating-labels .form-field select");
    if (floating_labels.length > 0 ){
        for (var i = 0; i < floating_labels.length; i++) {

            let input = floating_labels[i];

            if( input.value !== '' && !input.classList.contains('float-label') ){
                input.parentElement.classList.add('float-label');
            }

            input.addEventListener('focus', function(event) {

                if( !this.classList.contains('float-label') ){
                    this.parentElement.classList.add('float-label');
                }
            });

            input.addEventListener('blur', function(event) {

                if( this.value === '' ){
                    this.parentElement.classList.remove('float-label');
                }
            });

        }
    }
};