import React, { Component } from 'react'
import {connect} from "react-redux";
import {resetPOST} from "../../actions/croissant";
import svg_close from "../../static/images/icon-close.svg";

class Notifications extends Component {

    timeout;

    componentDidMount() {

        let self = this;

        this.timeout = setTimeout(function () {
            self.props.resetPOST();
        }, 30000)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let self = this;

        if( prevProps.POST !== undefined && this.props.POST !== undefined && prevProps.POST.message !== this.props.POST.message && this.props.POST.message !== '' ){
            clearTimeout(self.timeout)
        }
    }

    removeNotification = () => {
        this.props.resetPOST();
    }

    render(){

        if( this.props.POST ){
            return (
                <div className={"container notification-container status-" + this.props.POST.status} onClick={this.removeNotification}>
                    <div className="row">
                        <div className="col-12 notification">
                            <img src={svg_close} className="remove-notification" />
                            <p>{this.props.POST.message}</p>
                        </div>
                    </div>
                </div>
            )
        }

        return false
    }
}

const mapStateToProps = (state) => ({
    POST: state.croissant.POST,
})

const mapDispatchToProps = (dispatch) => ({
    resetPOST: () => {
        dispatch(resetPOST())
    },
})

export default connect(mapStateToProps,mapDispatchToProps)(Notifications)
