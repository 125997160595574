import React, { Component } from 'react'
import {Route, Routes} from "react-router-dom";
import SignUp from "./SignUp";
import SignOut from "./SignOut";

class Reminder extends Component {

    render() {

        return (
            <Routes>
                <Route path='*/afmelden/' component={SignOut}/>
                <Route path='*' component={SignUp}/>
            </Routes>
        );
    }
}

export default Reminder