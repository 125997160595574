import {
    CROISSANT_REQUEST,
    CROISSANT_SUCCESS,
    CROISSANT_FAILURE,
    CROISSANT_POST_REQUEST,
    CROISSANT_POST_SUCCESS,
    CROISSANT_POST_FAILURE,
    REMINDME_POST_REQUEST,
    REMINDME_POST_SUCCESS,
    REMINDME_POST_FAILURE,
    CROISSANT_POST_RESET,
    REMINDME_SIGNOUT_POST_RSAA, REMINDME_SIGNOUT_POST_FAILURE, REMINDME_SIGNOUT_POST_SUCCESS,
} from "../constants/croissant";

const initialState = {
    list: [],
    POST: undefined,
    errors: {},
};

export default (state = initialState, action) => {
    switch (action.type) {

        // Requests
        case CROISSANT_REQUEST:

            return Object.assign({}, state, {
                list: [],
                POST: undefined,
                errors: {}
            });

        case CROISSANT_POST_REQUEST:
        case REMINDME_POST_REQUEST:

            return Object.assign({}, state, {
                POST: undefined,
                errors: {}
            });

        // SUCCESS
        case CROISSANT_SUCCESS:

            return Object.assign({}, state, { list: action.payload });

        case CROISSANT_POST_SUCCESS:
        case REMINDME_POST_SUCCESS:
        case REMINDME_SIGNOUT_POST_SUCCESS:

            return Object.assign({}, state, {
                POST: action.payload,
            });

        case CROISSANT_FAILURE:

            return Object.assign({}, state, {
                POST: {
                    status: 'error',
                    message: 'Er was een probleem met het ophalen van de informatie.'
                },
            });

        case CROISSANT_POST_FAILURE:
        case REMINDME_POST_FAILURE:
        case REMINDME_SIGNOUT_POST_FAILURE:

            return Object.assign({}, state, {
                POST: {
                    status: 'error',
                    message: 'Er was een probleem met het versturen van de informatie.'
                },
            });

        case CROISSANT_POST_RESET:
            return Object.assign({}, state, {
                POST: undefined,
            });
        default:
            return state
    }
}