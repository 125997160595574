// FETCH entries per day
export const CROISSANT_REQUEST = '@@CROISSANT/CROISSANT_REQUEST';
export const CROISSANT_SUCCESS = '@@CROISSANT/CROISSANT_SUCCESS';
export const CROISSANT_FAILURE = '@@CROISSANT/CROISSANT_FAILURE';
export const CROISSANT_RSAA = [CROISSANT_REQUEST, CROISSANT_SUCCESS, CROISSANT_FAILURE];

// POST Croissant
export const CROISSANT_POST_REQUEST = '@@CROISSANT/CROISSANT_POST_REQUEST';
export const CROISSANT_POST_SUCCESS = '@@CROISSANT/CROISSANT_POST_SUCCESS';
export const CROISSANT_POST_FAILURE = '@@CROISSANT/CROISSANT_POST_FAILURE';
export const CROISSANT_POST_RSAA = [CROISSANT_POST_REQUEST, CROISSANT_POST_SUCCESS, CROISSANT_POST_FAILURE];

// POST Remind-me - Sign up
export const REMINDME_POST_REQUEST = '@@CROISSANT/REMINDME_POST_REQUEST';
export const REMINDME_POST_SUCCESS = '@@CROISSANT/REMINDME_POST_SUCCESS';
export const REMINDME_POST_FAILURE = '@@CROISSANT/REMINDME_POST_FAILURE';
export const REMINDME_POST_RSAA = [REMINDME_POST_REQUEST, REMINDME_POST_SUCCESS, REMINDME_POST_FAILURE];

// POST Remind-me - Sign Out
export const REMINDME_SIGNOUT_POST_REQUEST = '@@CROISSANT/REMINDME_SIGNOUT_POST_REQUEST';
export const REMINDME_SIGNOUT_POST_SUCCESS = '@@CROISSANT/REMINDME_SIGNOUT_POST_SUCCESS';
export const REMINDME_SIGNOUT_POST_FAILURE = '@@CROISSANT/REMINDME_SIGNOUT_POST_FAILURE =';
export const REMINDME_SIGNOUT_POST_RSAA = [REMINDME_SIGNOUT_POST_REQUEST, REMINDME_SIGNOUT_POST_SUCCESS, REMINDME_SIGNOUT_POST_FAILURE];

// SUBMIT (Reset)
export const CROISSANT_POST_RESET = '@@CROISSANT/CROISSANT_POST_RESET';
