import React, { Component } from 'react'
import Menu from './Menu'
import logo from '../../static/images/kiesjecroissant.svg'

class Header extends Component {

    render(){

        return (
            <header id="top">
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-md-4 logo-container">
                            <a href="/" className="logo">
                                <img src={logo} alt="Kies je Croissant" />
                            </a>
                        </div>
                        <div className="col-6 col-md-8 d-md-flex">
                            <div className="nav-container">
                                <div className="menu-button">
                                    <div id="nav-toggle"><span></span></div>
                                </div>

                                <nav className="header-nav">
                                    <Menu menu="header" />
                                </nav>
                                <nav className="off-canvas-nav">
                                    <Menu menu="mobilenav" />
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header
