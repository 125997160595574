import {RSAA} from 'redux-api-middleware';
import {API_URL} from '../constants';
import {
    CROISSANT_RSAA,
    CROISSANT_POST_RSAA,
    CROISSANT_POST_RESET, REMINDME_POST_RSAA, REMINDME_SIGNOUT_POST_RSAA,
} from "../constants/croissant";

export const fetchEntries = () => ({
    [RSAA]: {
        endpoint: API_URL + '?api=fetch-entries',
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        types: CROISSANT_RSAA
    }
});

export const postCroissant = (body) => ({
    [RSAA]: {
        endpoint: API_URL + '?api=post-croissant',
        method: 'POST',
        body: JSON.stringify({
            body: body,
        }),
        headers: {'Content-Type': 'application/json'},
        types: CROISSANT_POST_RSAA
    }
});

export const remindMe = (body) => ({
    [RSAA]: {
        endpoint: API_URL + '?api=remind-me',
        method: 'POST',
        body: JSON.stringify({
            body: body,
        }),
        headers: {'Content-Type': 'application/json'},
        types: REMINDME_POST_RSAA
    }
});

export const remindMeSignOut = (body) => ({
    [RSAA]: {
        endpoint: API_URL + '?api=remind-me-signout',
        method: 'POST',
        body: JSON.stringify({
            body: body,
        }),
        headers: {'Content-Type': 'application/json'},
        types: REMINDME_SIGNOUT_POST_RSAA,
    }
});

export const resetPOST = () => ({
    type: CROISSANT_POST_RESET,
})