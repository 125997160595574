import React, { Component } from 'react'
import {connect} from "react-redux";
import {remindMe} from "../../actions/croissant";
import {FloatingLabels} from "../Includes/FloatingLabels";

class SignUp extends Component {

    state = {
        fields: {
            email: '',
        },
        errors: {},
    }

    componentDidMount() {
        FloatingLabels();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        FloatingLabels();
    }

    handle_change = e => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState(prevstate => {
            const newState = {...prevstate};
            newState['fields'][name] = value;
            return newState;
        });
    };

    allowSubmit = () => {

        let allowed = true;
        let errors = {}

        let keys = Object.keys(this.state.fields)
        for(let i = 0; i < keys.length; i++){

            if( keys[i] === 'email' ){
                var regx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                let field_valid = regx.test(String(this.state.fields[keys[i]]).toLowerCase());
                if( !field_valid ){
                    allowed = false;
                    errors[keys[i]] = 'Vul een geldig e-mailadres in'
                }
            } else {
                let field_valid = this.state.fields[keys[i]] !== undefined && this.state.fields[keys[i]].trim() !== '' ? true : false
                if( !field_valid ){
                    allowed = false;
                    errors[keys[i]] = 'Vul een waarde in'
                }
            }
        }

        if( Object.keys(errors).length > 0 ){
            this.setState({ errors: errors })
        }

        return allowed;
    }

    onSubmit = (event) => {
        event.preventDefault();

        if( !this.allowSubmit() )
            return false;

        this.props.remindMe(this.state.fields)

    }

    render(){

        let form;

        form = (<form onSubmit={this.onSubmit} className="floating-labels">
            <div className="form-field">
                <label className="field-label" htmlFor="id_email">E-mailadres</label>
                <input type="text" name="email" id="id_email" value={this.state.fields.email} onChange={this.handle_change} />
                {Object.keys(this.state.errors).length > 0 && this.state.errors.email !== undefined ? <div className="field-error">{this.state.errors.email}</div> : '' }
            </div>

            <div className="form-submit">
                <input type="submit" name="submit" value="Aanmelden" />
            </div>
        </form>)


        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="page-content">
                            <h1>Stuur mij elke week een herinnering</h1>

                            {form}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    remindMe: (body) => {
        dispatch(remindMe(body))
    },
})


export default connect(null,mapDispatchToProps)(SignUp)
